body{
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}
.logotext{
  color: #fff;
    font-size: 14px;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 11rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
}
a:hover{
  text-decoration: underline !important;
}
.teamBox{
  width: 20%;
}
.margTopDesk{
  margin-top: 2em;
}
.TrailsImg{
 width: 100%;
 margin: 10px;
}
.enquireBtn {
  border: none;
  border-bottom: none;
  cursor: pointer;
  width: 170px;
  height: 34px;
  overflow: hidden;
  border-color: rgb(95, 128, 81);
  background-color: rgb(95, 128, 81);
  color: #ffffff;
  padding: 2px 0px 2px 0px;
  position: fixed;
  top: calc(50% - 42px);
  right: -68px;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  z-index: 9999999;
  font-size: 18px;
}
.enquireNowFormCont {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  background: #FFF;
  z-index: 9999999;
  width: 101%;
  padding: 15px;
  max-width: 500px;
  margin-right: -105%;
  transition: all .5s ease;
}
.active11 {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.42);
  z-index: 9999;
  width: 100%;
}
.enableEnquire {
  margin-right: 0;
}
.formtitle {
  background: #f6f6f6;;
  color: #000;
  text-align: center;
  min-height: 50px;
  padding-top: 7px;
  margin-bottom: 20px;
}
.closeBtn {
  background: #000;
  display: inline-block;
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  margin-left: -30px;
  top: 0;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding-left: 7px;
  cursor: pointer;
}
.logoMenu{
  width:154px;
  margin-left: 3em;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
h3, .h3, h3 a {
  color: #232323;
  font-size: 23px;
  line-height: 40px;
  font-weight: 400;
  text-transform: none;
}
.navbar-light .navbar-nav .nav-link {
  color: #0a0a0a !important;
  text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    padding: 0 12px;
}
.HomeBannerImg{
  width:100%;
}

.HomeBannerImgg2{
  width:80%;
}
.customSpacing1{
  padding-top: 4em;
}
h4, .h4, h4 a {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
}
h6, .h6, h6 a {
  color: #767676;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 400;
  text-transform: none;
}
.separator{
  position: relative;
    display: block;
    margin: 10px auto 20px;
  margin-top: 7px;
    margin-bottom: 38px;
    background-color: #5f8051;
    height: 3px;
    width: 200px;
}
.bgLightGrey{
  background-color: #f6f6f6;
}
.imgRes{
  width:100%;
}
.cardTitle{
  font-size: 1rem;
  line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #5f8051;
    margin-top: 32px;
    letter-spacing: 1px;
}
.display2{
  font-size: 2.5rem;
  line-height: 52px;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 45px;
}
.aUrl{
  border: 2px solid #303030;
  position: relative;
    display: inline-block;
    width: auto;
  color: rgb(10, 10, 10);
    border-color: rgb(10, 10, 10);
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    height: 47px;
    line-height: 47px;
    font-size: 16px;
    padding: 0 15px;
    text-align: center;
    transition: color .1s linear,background-color .1s linear,border-color .1s linear;
    text-decoration: none;
    font-weight: 700;
}
.aUrl:hover{
  color: rgb(255, 255, 255);
    border-color: rgb(95, 128, 81);
    border-radius: 25px;
    background-color: rgb(95, 128, 81);
}

.acUrl{
  border: 2px solid #303030;
  position: relative;
    display: inline-block;
    width: auto;
  color: rgb(10, 10, 10);
    border-color: rgb(10, 10, 10);
    border-radius: 25px;
    background-color: rgb(197, 169, 113);
    height: 65px;
    line-height: 30px;
    font-size: 16px;
    padding: 0 15px;
    text-align: center;
    transition: color .1s linear,background-color .1s linear,border-color .1s linear;
    text-decoration: none;
    font-weight: 700;
}
.acUrl:hover{
  color: rgb(255, 255, 255);
    border-color: rgb(95, 128, 81);
    border-radius: 25px;
    background-color: rgb(95, 128, 81);
}

.CustomPadding2{
  padding-top: 3em;
  padding-bottom: 3em;
}
.CustomPadding2Btn{
  padding-top: 3em;
  padding-bottom: 3em;
}
.footer{
  background-color: #262626;
  padding-top: 3em;
  padding-bottom: 3em;
  color: #818181;
    word-wrap: break-word;
}
.logoFooter{
  width:150px
}
.footerBottom{
  text-align: center;
  background-color: #000;
  color: #818181;
  padding: 14px;
}
.marginBottom24px{
  margin-bottom: 24px;
}

.margintopBottom24px {
  margin-bottom: 24px;
  margin-top: 40px;
}


.figure {
  border: thin #c0c0c0 solid;
  display: flex;
  flex-flow: column;
  padding: 0px;
  max-width: 100%;
  margin: auto;
}

.figcaption {
  background-color: #f6f6f6;
  color: #232323;
  font: italic smaller sans-serif;
  padding: 3px;
  text-align: center;
}


.innerSubHeading{
  color: #327e38;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 300;
}
.paraStyle {
  font-style: normal;
  line-height: 1.6;
  color: #232323;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-size: 1.2rem;
}
.paraStyle22 {
  font-style: normal;
  line-height: 1.6;
  color: #232323;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-size: 1.2rem;
    padding: 15px;
}

.customSpacing3 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.customSpacing33 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.customSpacing4 {
  padding-top: 4em;
  padding-bottom: 4em;
}
.aboutLogo {
  padding: 10%;
  margin-top: 32%;
  width:100%;
}
.timeLineBlock {
  padding: 2rem 2.5rem;
  background: #5f8051;
  color: #fff;
  border-radius: 0;
}
.dateBlock{
  width: 185px;
  padding: 1em;
}
.floatRight{
  float:right;
}
.timelineTitle {
  color: #e5c61e;
}
.border-right {
  border-right: 1px solid #dee2e6!important;
}
.borderCircle {
  border: 1px solid #5f8051!important;
  border-radius: 46px;
  background: #5f8051!important;
}
.greyColorText{
  color: #767676;
}
.paraStyle2{
  margin-top: 1em;
  margin-bottom: 0;
}
.boxStyle{
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
}
.boxStyle2{
  background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    width: 48%;
    margin-left: 2%;
}
.partnerMarginBtm{
  margin-bottom:5%;
}
.youtube-video-container {
  position: relative;
  overflow: hidden;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.text-right{
  text-align: right;
}
.minHeight2em{
  min-height: 8em;
}
.text-left{
  text-align: left;
}
.display-5 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.3rem;
  color: #767676;
}
.inputType{
  background-color: #f5f5f5;
    box-shadow: none;
    color: #565656;
    line-height: 1.43;
    min-height: 3.5em;
    padding: 1.07em 0.5em;
  font-size: 1rem;
  border: 1px solid #e8e8e8;
  width:100%
}
.inputTypeTextArea{
  background-color: #f5f5f5;
    box-shadow: none;
    color: #565656;
    line-height: 1.43;
    min-height: 3.5em;
    padding: 1.07em 0.5em;
  font-size: 1rem;
  border: 1px solid #e8e8e8;
  width:100%;
  min-height: 100px;
}
.inputGroup{
  margin-bottom: 15px;
}
.formSubmit {
  border-radius: 100px !important;
  padding: 1rem 3rem;
  color: #ffffff !important;
    background-color: #3c5133 !important;
    border-color: #3c5133 !important;
    width:150px;
    border: 1px solid;
}
.frame1{
  margin-bottom: 30px;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.vericalMiddle{
  vertical-align: middle;
}
.footerLinka{
  color: #818181!important;
  text-decoration: none!important;
    display: block;
    padding-left: 0;
}
.regularMnu{
  transition: all .5s ease;
}
.fixedheader{
  position: fixed;
  width: 100%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 11%);
    z-index: 99999;
}
.fixedheader .logoMenu {
  width: 83px;
}
.desktopHide{
  display:none;
}
.mobileHide{
  display:block;
}
.closeButton {
  border: none;
  background: transparent;
  float: right;
  font-size: 20px;
}
.img_res{
  width:100%
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  text-align: right;
  padding:5px 20px;
  font-size: 18px;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
  background-color: #f6f6f6;
  margin-bottom: 2rem; 
}
.bgbrownPaithani{
  background: #d3be95;
  font-weight: bold;
  font-size: 22px;
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 70%;
    margin: 1.75rem auto;
}
}
@media screen and (max-width: 760px) {
  .customSpacing3 {
    padding-top: 2em;
    padding-bottom: 1em;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  text-align: right;
  padding: 5px 8px;
  font-size: 14px;
}
.paraStyle {
  font-style: normal;
  line-height: 1.6;
  color: #232323;
  text-align: left;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
}
  .logotext {
    color: #fff;
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: uppercase;
}
  .CustomPadding2Btn{
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .teamBox {
    width: 50%;
}
  .closeBtn {
    background: #000;
    display: inline-block;
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    margin-left: 2px;
    top: 0;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    padding-left: 7px;
    cursor: pointer;
}


  .boxStyle2 {
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    width: 100%;
    margin-left:0%;
}
.partnerMarginBtm {
  margin-bottom: 5%;
}
.mobimg50{
  width:50%;
}
  
  .PaymentButton.svelte-ekc7fv.svelte-ekc7fv {
    position: relative;
    display: inline-block;
    min-width: 141px !important;
    height: 40px;
    padding: 0;
    border-radius: 3px;
    text-align: center;
    font-style: italic;
    font-family: Muli,helvetica,sans-serif;
    font-display: swap;
    overflow: hidden;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
}
.logoMenu {
  width: 103px;
    margin-left:0px;
  
}
.mobileHide{
  display:none;
}
.desktopHide{
  display:block;
}
.aboutLogo {
  margin-top: 0;
  width: 60%;
  padding: 0;
  margin-left: 20%;
  margin-bottom: 30px;
}
.display2 {
  font-size: 2.4rem;
  font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
  line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
}
.mobTextLeft{
  text-align: left;
}
.margMobBtm{
  margin-bottom: 1em;
  margin-top: 1em;
}
.mobMarginTop2 {
  margin-top: 2em;
}
.justifyMobtxt{
text-align: justify;
}
.customSpacing4 {
  padding-top: 2em;
  padding-bottom: 2em;
}
}